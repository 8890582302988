import { apiv1 } from "@/request/apiv1";
// import qs from "qs";

export function LoginByWechatCode(code = "") {
  return new Promise((resolve, reject) => {
    apiv1
      .get(`/v1/auth/wechat/qr_code?code=${code}`)
      .then(res => {
        if (res.status === 200 || res.status === 206) {
          resolve(res);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function RequstTokenBySms(phoneNo = "", code = "") {
  return new Promise((resolve, reject) => {
    apiv1
      .post(`/v1/auth/login/sms`, {
        phoneNo: phoneNo,
        code: code
      })
      .then(res => {
        if (res.status === 201 || res.status === 202) {
          resolve(res);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function PostLoginCodeByPhoneNumber(phoneNo = "") {
  return new Promise((resolve, reject) => {
    apiv1
      .post(`/v1/sms/code/login`, {
        phoneNo
      })
      .then(res => {
        if (res.status === 201 || res.status === 202) {
          resolve(res);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
}
