<template>
  <div class="login-wrapper">
    <div class="login-container">
      <section class="login-wrap-header">
        <span class="logo"
          ><img src="../../assets/logo.png" alt="" class="vg-logo"
        /></span>
        <!-- <img src="../../assets/slogan.png" alt="" class="slogan-logo" /> -->
        <!-- <span class="system-name"></span> -->
      </section>
      <div class="login-form-container base-shadow">
        <div class="login-banner">
          <p class="banner-title weight">数据可视化</p>
          <p class="banner-title light">让招商更容易</p>
          <div class="draw-content">
            <span class="svg-draw"
              ><svg-icon iconClass="undraw_trends"></svg-icon
            ></span>
          </div>
          <!-- <img src="../../assets/login-banner.jpg" alt="" class="login-banneer" /> -->
        </div>
        <div class="login-form">
          <div class="toggle-btn-group">
            <div
              class="toggle-btn"
              :class="[loginType == 'phone' ? 'active base-shadow' : '']"
              @click="toggleLoginType('phone')"
            >
              手机号登录
            </div>
            <div
              class="toggle-btn"
              :class="[loginType == 'account' ? 'active base-shadow' : '']"
              @click="toggleLoginType('account')"
            >
              账号密码登录
            </div>
          </div>
          <AccountLoginForm
            :redirect="redirect"
            v-show="loginType == 'account'"
          />
          <SmsLoginForm :redirect="redirect" v-show="loginType == 'phone'" />
        </div>
      </div>
    </div>

    <div class="login-footer">
      Copyright &copy; 欧若拉精工奢石 All rights reserved.
      <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备19063394号</a>
    </div>
  </div>
</template>

<script>
import AccountLoginForm from "./components/AccountLoginForm";
import SmsLoginForm from "./components/SmsLoginForm";
export default {
  components: {
    AccountLoginForm,
    SmsLoginForm,
  },
  data() {
    return {
      loading: false,
      redirect: "",
      loginType: "account",
    };
  },
  created() {
    if (this.$route.query.message) {
      this.$message.error(this.$route.query.message);
    }
    if (this.$route.query.redirect) {
      this.redirect = this.$route.query.redirect;
    }
  },
  methods: {
    toggleLoginType(type) {
      this.loginType = type;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/custom-variables.scss";
$base-width: 960px;
$base-color: #03a1ea;
.login-wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background-color: #f4f9fe;

  position: relative;
  padding-bottom: 50px;
  background-image: radial-gradient(
      circle at 13% 47%,
      rgba(140, 140, 140, 0.03) 0%,
      rgba(140, 140, 140, 0.03) 25%,
      transparent 25%,
      transparent 100%
    ),
    radial-gradient(
      circle at 28% 63%,
      rgba(143, 143, 143, 0.03) 0%,
      rgba(143, 143, 143, 0.03) 16%,
      transparent 16%,
      transparent 100%
    ),
    radial-gradient(
      circle at 81% 56%,
      rgba(65, 65, 65, 0.03) 0%,
      rgba(65, 65, 65, 0.03) 12%,
      transparent 12%,
      transparent 100%
    ),
    radial-gradient(
      circle at 26% 48%,
      rgba(60, 60, 60, 0.03) 0%,
      rgba(60, 60, 60, 0.03) 6%,
      transparent 6%,
      transparent 100%
    ),
    radial-gradient(
      circle at 97% 17%,
      rgb(0, 157, 104, 0.03) 0%,
      rgb(0, 157, 104, 0.03) 56%,
      transparent 56%,
      transparent 100%
    ),
    radial-gradient(
      circle at 50% 100%,
      rgba(25, 25, 25, 0.03) 0%,
      rgba(25, 25, 25, 0.03) 36%,
      transparent 36%,
      transparent 100%
    ),
    radial-gradient(
      circle at 55% 52%,
      rgba(69, 69, 69, 0.03) 0%,
      rgba(69, 69, 69, 0.03) 6%,
      transparent 6%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
  overflow: hidden;
  .login-container {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .login-wrap-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 50px;
    width: $base-width;
    margin: 0 auto;
    .logo {
      display: inline-block;
      min-width: 80px;
      height: 80px;
      text-align: left;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .slogan-logo {
      max-height: 50px;
      margin-left: 20px;
    }
    .system-name {
      display: inline-block;
      padding-left: 20px;
      margin-left: 20px;
      font-size: 32px;
      font-weight: 400;
      color: #333;
      position: relative;
      // &::before {
      //   content: "";
      //   width: 2px;
      //   height: 32px;
      //   background-color: #333;
      //   position: absolute;
      //   top: 50%;
      //   left: 0;
      //   transform: translateY(-50%);
      // }
    }
  }
  .login-form-container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: $base-width;
    height: 450px;
    margin: 0 auto;
    background-color: #fff;
  }
  .login-footer {
    width: 100%;
    height: 50px;
    font-size: 14px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .login-banner {
    flex: 1;
    box-sizing: border-box;
    height: 100%;
    padding: 30px;
    padding-top: 50px;
    background: $theme_color;
    // background: linear-gradient(to left, #ffffff, #6dd5fa, #349fe6);
    overflow: hidden;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    .banner-title {
      font-size: 26px;
      text-align: left;
      color: #fff;
      padding-left: 52px;
      margin-bottom: 5px;
      &.weight {
        font-weight: 500;
        // font-family: "Alibaba-PuHuiTi-Regular";
      }
      &.light {
        font-weight: 300;
        // font-family: "Alibaba-PuHuiTi-Light";
      }
    }
    .draw-content {
      width: 100%;
      height: 300px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .svg-draw {
        font-size: 370px;
      }
    }
  }
  .login-form {
    box-sizing: border-box;
    flex: 400px 0 0;
    max-width: 400px;
    height: 100%;
    margin: 0 auto;
    padding: 50px;
    padding-top: 80px;
    padding-bottom: 0;
    position: relative;
    overflow-y: hidden;
    .other-login-item {
      box-sizing: border-box;
      padding: 10px 0;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      color: #000;
      .other-login-title {
        padding-right: 20px;
      }
      .other-login-icon {
        font-size: 24px;
        transition: all 0.3s linear;
        cursor: pointer;
        color: #989898;
        &.wechat {
          &:hover {
            color: #52c41a;
          }
        }
      }
    }
  }
  .toggle-btn-group {
    box-sizing: border-box;
    padding: 0 50px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    .toggle-btn {
      flex: 1;
      box-sizing: border-box;
      padding: 10px 10px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      &:hover {
        color: $theme_color;
      }
      &.active {
        transition: all 0.3s linear;
        background-color: $theme_color;
        color: #fff;
        transform: translateY(-1px);
      }
    }
  }
}
</style>
